import React from 'react';

import s from './temp.scss';

import { Segment } from 'components/segment/Segment';
import SEO from 'components/seo';

// tslint:disable no-default-export
export default () => (
  <>
    <SEO title="Help page" />

    <Segment className={s.temp}>
      <h1 className={s.temp__title}>Temp Page</h1>

    </Segment>
  </>
);
